






















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { INewsletter, INewsletterMessage, INewsletterCreate, IUserService } from '@/interfaces';
import VTextAreaWithValidation from '@/components/VTextAreaWithValidation.vue';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import VSelectWithValidation from '@/components/VSelectWithValidation.vue';
import VFileInputWithValidation from '@/components/VFileInputWithValidation.vue';
import TZAutocomplete from '@/components/common/autocompletes/timezone/TZAutocomplete.vue';
import { dateToISOFormat } from '@/utils';
import { ValidationProvider } from 'vee-validate';
import {
  dispatchGetUserProfile,
  readUserProfile,
  dispatchCreateNewsletter,
  dispatchCreateNewsletterMessages,
  dispatchUploadNewsletterReceiversFile,
} from '@/store/main/accessors';

@Component({
  components: {
    VFileInputWithValidation,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VTextAreaWithValidation,
    TZAutocomplete,
  },
})
export default class SendMessage extends Vue {
  public valid = false;
  public name = '';
  public service: string = '';
  public sender: string = '';
  public receivers: string = '';
  public receiversFile: File | null = null;
  public text: string = '';
  public servicesList = [];
  public checkSender: boolean = false;
  public sendersList = [];
  public services;
  public isTZConsidered: boolean = false;
  public datetime: Date | null = null;
  public timezone: string = '';
  public timeProps = {
    ampmInTitle: false,
    format: '24hr',
    scrollable: true,
    fullWidth: true,
  };
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
    datetimepicker: any;
  };

  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    const user = readUserProfile(this.$store);
    if (user) {
      this.services = new Map<string, IUserService>(
        user.services.map((item) => [item.service, item] as [string, IUserService]),
      );
      this.servicesList = Array.from(this.services.keys());
      await this.reset();
    }
  }

  public async reset() {
    this.name = '';
    this.service = '';
    this.sender = '';
    this.datetime = this.$refs.datetimepicker.clearHandler();
    this.isTZConsidered = false;
    this.timezone = '';
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const newsLetter: INewsletterCreate = {
        name: this.name,
        service: this.service,
        sender: this.sender,
        scheduled_dt: dateToISOFormat(this.datetime, this.timezone),
        is_tz_considered: this.isTZConsidered,
      };

      const result: INewsletter = await dispatchCreateNewsletter(this.$store, newsLetter);

      if (result?.id) {
        if (this.receiversFile) {
          const fileData: { id: number; file: File } = { id: result.id, file: this.receiversFile };
          await dispatchUploadNewsletterReceiversFile(this.$store, fileData);
        }

        const receivers = this.receivers.split('\n').filter((row) => row.trim());
        if (receivers.length > 0) {
          const body: INewsletterMessage[] = [];
          for (const receiver of receivers) {
            body.push({ text: this.text, receiver });
          }
          const data: { id: number; body: INewsletterMessage[] } = { id: result.id, body };
          await dispatchCreateNewsletterMessages(this.$store, data);
        }

        this.$router.push({ name: 'main-newsletter-all' });
      }
    }
  }

  public timezoneToggle() {
    return !this.isTZConsidered;
  }

  @Watch('service')
  public setSendersList() {
    this.sender = '';
    this.sendersList = this.services.get(this.service).allowed_senders;
    this.checkSender = this.services.get(this.service).check_sender;
    this.$refs.observer.reset();
  }
}
